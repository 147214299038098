'use client';

import React, { PropsWithChildren } from 'react';
import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './Section.module.scss';
import {
    AnimationType,
    useAnimation,
} from '../animations';
import SectionTitle, { Tags } from '@app/ui/Section/SectionTitle';

export interface IProps extends PropsWithChildren {
    hideTitle?: boolean;
    title?: string | JSX.Element;
    mobileTitle?: string | JSX.Element;
    filled?: boolean;
    animation?: AnimationType;
    stretch?: SectionStretchType;
    action?: React.ReactNode | JSX.Element;
    /**
     * Дополнительное действие для мобильной версии
     * Отображается снизу, после контента и футера
     * Если не передается, в мобильной версии отображается стандартный action возле заголовка
     */
    mobileAction?: React.ReactNode | JSX.Element;
    footer?: React.ReactNode | JSX.Element;
    className?: any;
    tag?: Tags;
    /**
     * ID секции
     */
    id?: string;
}

export type SectionStretchType = SectionStretchOption | SectionStretchOption[]

type SectionStretchOption = 'left' | 'right' | 'horizontally' | 'top' | 'bottom' | 'vertically'

const STRETCH_HORIZONTALLY_OPTIONS: SectionStretchOption[] = ['left', 'right', 'horizontally'];

const STRETCH_VERTICALLY_OPTIONS: SectionStretchOption[] = ['top', 'bottom', 'vertically'];

const getSectionStretchClassNames = (stretch: SectionStretchType, type: 'horizontal' | 'vertical'): string[] => {
    if (!stretch) {
        return [];
    }

    const stretchOptions = (type === 'horizontal') ? STRETCH_HORIZONTALLY_OPTIONS : STRETCH_VERTICALLY_OPTIONS;

    const classNameBase = (type === 'horizontal') ? 'section__body--stretched' : 'section--stretched';

    const classNamesList = [];

    const fillClassNamesList = (direction: SectionStretchOption) => {
        if (stretchOptions.includes(direction)) {
            classNamesList.push(`${classNameBase}-${direction}`);
        }
    };

    if (Array.isArray(stretch)) {
        stretch.forEach(direction => fillClassNamesList(direction));
    }

    if (typeof stretch === 'string') {
        fillClassNamesList(stretch);
    }

    return classNamesList;
};

const Section: React.FC<IProps> = props => {
    const {
        children,
        title,
        filled = true,
        stretch,
        animation = 'slide-up',
        action,
        footer,
        tag,
        className,
        id,
        mobileAction,
    } = props;

    const mobileTitle = props.mobileTitle || props.title;

    const [ ref, animationClassName ] = useAnimation(animation);


    const sectionClassNames = mergeClassNames([
        buildClassNames(styles, [
            'section',
            filled && 'section--filled',
            ...getSectionStretchClassNames(stretch, 'vertical'),
        ]),
        animationClassName,
        className,
    ]);

    const sectionBodyClassNames = buildClassNames(styles, [
        'section__body',
        ...getSectionStretchClassNames(stretch, 'horizontal'),
    ]);

    const headerRequired = props.hideTitle !== true
        ? Boolean(title || action)
        : Boolean(action);

    return (
        <section ref={ref} className={sectionClassNames} id={id}>
            {headerRequired && (
                <div className={styles['section__header']}>
                    <SectionTitle title={title} mobileTitle={mobileTitle} tag={tag} />
                    {action && (
                        <div className={styles['section__header__action']}>
                            {action}
                        </div>
                    )}
                </div>
            )}
            <div className={sectionBodyClassNames}>
                {children}
            </div>
            {footer && (
                <div className={styles['section__footer']}>
                    {footer}
                </div>
            )}
            {mobileAction && (
                <div className={styles['section__footer__action']}>
                    {mobileAction}
                </div>
            )}
        </section>
    );
};

export default Section;